/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { InputGroup, InputGroupAddon } from "reactstrap";
import CheckLogin from "../forms/CheckLogin";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { Finder } from "components/standart";
import { phoneNumber, FloatModal, setOnChange, AccessControl } from "components/special";

const { api } = adminAPI.actions;

const FloatMemeGeneratesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="Special.MemeGenerates" />}
      {...props}
      size="lg"
      head={(searchData, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Special.MemeGenerates.name" />
            </th>
            <th>
              <IntlMessages id="Special.MemeGenerates.content" />
            </th>
          </>
        );
      }}
      row={(generate, clickHandler) => (
        <>
          <td onClick={clickHandler}>
            <div>{generate.name}</div>
          </td>
          <td onClick={clickHandler}>
            <div>{generate.content}</div>
          </td>
        </>
      )}
    />
  );
};

const FloatMemeGenerates = (props) => {
  return <FloatMemeGeneratesModal {...props} />;
};

export default connect(null, {
  getData: api.get("/api/special/meme_generates"),
  // addCustomer: api.auto("Customers.Add"),
})(FloatMemeGenerates);
