import { RECEIPT_TYPES, RECEIPT_STATUSES } from "cloclo/constants";

export default {
  title: "Административная панель | Astrogotchi",
  profile: "Профиль",
  settings: "Настройки",
  "settings.photo": "Фотография",
  "settings.changePassword": "Смена пароля",
  "settings.changePassword.newPassword": "Новый пароль",
  "settings.changePassword.repeatPassword": "Повторите ввод",
  "settings.changePassword.submit": "Изменить",
  "settings.changePassword.success": "Пароль успешно изменен",
  "settings.changePassword.error": "Ошибка изменения пароля",
  "settings.photo.upload": "Загрузить",

  Dashboard: "Отчеты",
  "Dashboard.date": "Дата",
  "Dashboard.value": "Значение",

  "Dashboard.scale": "Период",
  "Dashboard.scale.week": "Неделя",
  "Dashboard.scale.month": "Месяц",
  "Dashboard.scale.year": "Год",

  "Dashboard.ItemsCategories": "Категории",
  "Dashboard.ItemsCategories.Dynamic": "Активации",
  "Dashboard.Customers.DynamicGender": "Регистрации по полу",
  "Dashboard.Customers.DynamicZodiac": "Регистрации по знакам",
  "Dashboard.Customers.SummaryGender": "Пол",
  "Dashboard.Customers.SummaryAge": "Возраст",
  "Dashboard.Customers.ScoresSummary": "Оценки",
  "Dashboard.Customers.SummaryZodiac": "Знаки",

  "Dashboard.Products": "Cклад",
  "Dashboard.Payments.Dynamic": "Платежи",
  "Dashboard.Payments.Summary": "Сводка платежей",
  "Dashboard.Payments.CurrencySummary": "Доходы {currencyName}",
  "Dashboard.Payments.CurrencySummary.ItemsCategories": "Цифровые Астрогочи",
  "Dashboard.Payments.CurrencySummary.premiums": "Премиумы",
  "Dashboard.Payments.CurrencySummary.gifts": "Подарки",
  "Dashboard.Orders": "Заказы",
  "Dashboard.Orders.Count": "Количество заказов",
  "Dashboard.Orders.Total": "Деньги с заказов",
  "Dashboard.Payouts": "Выплаты",
  "Dashboard.Products.Dynamic": "Динамика склада",

  "Dashboard.KPI": "KPI",
  "Dashboard.KPI.newCount": "Новые",
  "Dashboard.KPI.notNewCount": "Повторные",

  "Dashboard.Payments": "Деньги",
  "Dashboard.Payments.payouts": "Фонды",
  "Dashboard.Payments.commission": "Комиссия",
  "Dashboard.Payments.waitForPayouts": "Не распределены",

  // перевода для интерфейсов управления учетными записями
  Accounts: "Учетные записи",

  // перевода для интерфейсов управления пользователями
  Users: "Пользователи",
  "Users.Add": "Добавить пользователя",
  "Users.User": "Данные пользователя",
  "Users.User.Save": "Редактировать пользователя",
  "Users.Form.changePhone": "Изменить номер телефона",
  "Users.info": "Информация",
  "Users.isAdmin": "Админ",
  "Users.login": "Логин",
  "Users.name": "Имя",
  "Users.phone": "Номер телефона",
  "Users.password": "Пароль",
  "Users.access": "Права доступа",
  "Users.add": "Добавить",
  "Users.login-busy": "Логин занят",
  "Users.table.login": "Логин",
  "Users.table.name": "Имя",

  Logins: "Данные для авторизации",
  "Logins.Add": "Добавить логин",
  "Logins.Edit": "Изменить логин",
  "Logins.value": "Логин",
  "Logins.type": "Тип",
  "Logins.type.plain": "Обычный",
  "Logins.type.phone": "Телефон",
  "Logins.type.email": "Email",

  Accesses: "Права доступа",
  "Accesses.Add": "Добавить право доступа",
  "Accesses.Form": "Редактировать права доступа",
  "Accesses.title": "Название",
  "Accesses.components": "Компоненты",

  Catalogs: "Настройки",

  Customers: "Клиенты",
  "Customers.Add": "Создать клиента",
  "Customers.Form": "Редактировать данные клиента",
  "Customers.Form.info": "Информация",
  "Customers.Form.addresses": "Адреса",
  "Customers.Info": "Информация",
  "Customers.Items": "Астрогочи",
  "Customers.Data": "Данные клиента",
  "Customers.name": "Имя",
  "Customers.surname": "Фамилия",
  "Customers.birthDate": "Дата рождения",
  "Customers.astroSign": "Знак зодиака",
  "Customers.phone": "Телефон",
  "Customers.email": "E-mail",
  "Customers.itemsCount": "Заказы",
  "Customers.ident": "ID",

  // заказы
  Orders: "Заказы",
  "Orders.Order": "Данные заказа",
  "Orders.info": "Информация",
  "Orders.ident": "ID",
  "Orders.costs": "Цена",
  "Orders.customer": "Клиент",
  "Orders.partner": "Партнер",
  "Orders.payout": "Выплата",
  "Orders.treeCost": "Цена",
  "Orders.commission": "Комиссия",

  Payments: "Транзакции",
  "Payments.value": "Сумма",
  "Payments.currency": "Валюта",
  "Payments.customer": "Клиент",
  "Payments.status": "Статус",
  "Payments.status.new": "Новая",
  "Payments.status.wait_payment": "Ожидает оплаты",
  "Payments.status.paid": "Оплачена",
  "Payments.status.error": "Ошибка",
  "Payments.status.declined": "Отменена",

  "Payments.Transactions": "Платежи",
  "Payments.Receipts": "Чеки",
  "Payments.ReceiptsServices": "Чековые сервисы",
  "Payments.PaymentsServices": "Платежные сервисы",
  "Payments.Currencies": "Валюты",
  "Payments.Currencies.marketplaces": "Используется на площадках",
  "Payments.Currencies.isoName": "ISO4217 название",
  "Payments.Currencies.isoCode": "ISO4217 цифровой код",

  Countries: "Страны",
  "Countries.code": "Код",
  "Countries.currency": "Валюта",

  Receipts: "Чеки",
  "Receipts.Receipt": "Чек",
  "Receipts.type": "Тип",
  [`Receipts.type.buy_tree`]: "Покупка дерева",

  "Receipts.customer": "Клиент",
  "Receipts.status": "Статус",
  [`Receipts.status.new`]: "Новый",
  [`Receipts.status.new.style`]: "secondary",
  [`Receipts.status.ready`]: "Готов к обработке",
  [`Receipts.status.ready.style`]: "secondary",
  [`Receipts.status.process`]: "В обработке",
  [`Receipts.status.process.style`]: "secondary",
  [`Receipts.status.success`]: "Завершен",
  [`Receipts.status.success.style`]: "success",
  [`Receipts.status.error`]: "Ошибка",
  [`Receipts.status.error.style`]: "danger",

  Genders: "Пол",
  "Genders.male": "Мужской",
  "Genders.female": "Женский",
  "Genders.null": "Неизвестно",

  Locales: "Локализации",
  "Locales.Locale": "Язык",
  "Locales.icon": "Иконка",
  "Locales.code": "Код",
  "Locales.isDefault": "Язык по умолчанию",
  "Locales.ru": "Русский",
  "Locales.en": "English",

  Marketplaces: "Торговые площадки",
  "Marketplaces.bgColor": "Цвет фона",
  "Marketplaces.fontColor": "Цвет шрифта",
  "Marketplaces.logo": "Логотип",
  "Marketplaces.url": "Ссылка",

  PaymentsServicesTypes: "Платежный сервис",
  "PaymentsServicesTypes.yoo_kassa": "Ю-Касса",
  "PaymentsServicesTypes.tinkoff_kassa": "Тинькофф-Касса",
  "PaymentsServicesTypes.stripe": "Stripe",
  "PaymentsServicesTypes.telegram": "Телеграм",

  ReceiptsServicesTypes: "Кассовый сервис",
  "ReceiptsServicesTypes.cloud_kassir": "Cloud Kassir",
  "ReceiptsServicesTypes.kit_online": "KIT online",

  AstroSigns: "Знаки зодиака",
  "AstroSigns.aries": "Овен",
  "AstroSigns.taurus": "Телец",
  "AstroSigns.gemini": "Близнецы",
  "AstroSigns.cancer": "Рак",
  "AstroSigns.leo": "Лев",
  "AstroSigns.virgo": "Дева",
  "AstroSigns.libra": "Весы",
  "AstroSigns.scorpio": "Скорпион",
  "AstroSigns.sagittarius": "Стрелец",
  "AstroSigns.capricorn": "Козерог",
  "AstroSigns.aquarius": "Водолей",
  "AstroSigns.pisces": "Рыбы",
  "AstroSigns.null": "Неизвестно",

  AstroAspects: "Аспект",
  "AstroAspects.square": "Квадрат",
  "AstroAspects.trine": "Трин",
  "AstroAspects.opposition": "Оппозиция",
  "AstroAspects.sextile": "Секстиль",
  "AstroAspects.conjunction": "Соединение",

  Planets: "Планета",
  "Planets.sun": "Солнце",
  "Planets.moon": "Луна",
  "Planets.mercury": "Меркурий",
  "Planets.venus": "Венера",
  "Planets.mars": "Марс",
  "Planets.jupiter": "Юпитер",
  "Planets.saturn": "Сатурн",
  "Planets.uranus": "Уран",
  "Planets.neptune": "Нептун",
  "Planets.pluto": "Плутон",

  Contents: "Контент",
  "Contents.Add": "Добавить",
  "Contents.Form": "Редактировать",
  "Contents.name": "Название",
  "Contents.value": "Значение",
  "Contents.text": "Текст",
  "Contents.textUp": "Текст сверху",
  "Contents.textDown": "Текст снизу",
  "Contents.tag": "Тег",
  "Contents.icon": "Иконка",
  "Contents.astroAspect": "Аспект",
  "Contents.isPaid": "Платный доступ",
  "Contents.category": "Категория",
  "Contents.topic": "Тема",
  "Contents.Preferences": "Предпочтения",
  "Contents.AspectsQuotes": "Предсказания по аспектам",
  "Contents.AspectsCompatibilities": "Совместимость по аспектам",
  // "Contents.AspectsDates": "Даты аспектов",
  "Contents.AspectsValues": "Аспекты",
  "Contents.AspectsCategories": "Категории",
  "Contents.DailyQuotes": "Цитаты",
  "Contents.DailyQuotesCategories": "Категории цитат",
  "Contents.DailyQuotesTopics": "Темы цитат",
  "Contents.Gradients": "Диапазоны",
  "Contents.gradient": "Диапазон",
  "Contents.GradientsTexts": "Описание диапазонов",
  "Contents.AstroWeeks": "Прогнозы на неделю",
  "Contents.AstroWeeks.date": "Дата",
  "Contents.AstroWeeks.fromDate": "С даты",
  "Contents.AstroWeeks.toDate": "По дату",
  "Contents.AstroSigns": "Знаки",
  "Contents.AstroOverlaps": "Совместимость",
  "Contents.Celebrities": "Знаменитости",

  Items: "Товары",
  "Items.List": "Регистрации",
  "Items.Add": "Добавить товар",
  "Items.Categories": "Категории",
  "Items.Categories.Add": "Добавить категорию",
  "Items.Categories.QrCode": "QR-code",
  "Items.Categories.name": "Название",
  "Items.Categories.alias": "Ссылка",
  "Items.Categories.avatar": "Ававатка",
  "Items.Categories.images": "Изображения",
  "Items.Categories.description": "Описание",
  "Items.Categories.greetingHeader": "Заголовок приветствия",
  "Items.Categories.greetingText": "Текст приветствия",
  "Items.Categories.price": "Цена",
  // "Items.Categories.oldPrice": "Старая цена",
  "Items.Categories.urls": "Ссылки на маркетплейсы",
  "Items.Categories.digitalOnly": "Только цифровой",
  "Items.Categories.notInSale": "Нет в продаже",
  "Items.category": "Категория",
  "Items.emit": "Партия",
  "Items.ident": "Код",
  "Items.activateDate": "Дата активации",
  "Items.Emits": "Партии",
  "Items.Emits.Add": "Новая партия",
  "Items.Emits.quantity": "Количество",
  "Items.Emits.comment": "Комментарий",

  "Items.Types": "Типы продукции",
  "Items.Types.Add": "Добавить тип",
  "Items.Types.name": "Название",
  "Items.Types.isDigital": "Цифровой",

  Premiums: "Премиумы",
  "Premiums.Add": "Добавить премиум",
  "Premiums.Form": "Редактировать данные",
  "Premiums.Info": "Информация",
  "Premiums.name": "Название",
  "Premiums.duration": "Длительность, месяцы",
  "Premiums.prices": "Цены",
  "Premiums.file": "Иллюстрация",
  "Premiums.localesButton": "Текст на кнопке",

  Memes: "Мемы",
  "Memes.Meme": "Мем",
  "Memes.Add": "Добавить мем",
  "Memes.Form": "Редактировать данные",
  "Memes.Info": "Информация",
  "Memes.name": "Название",
  "Memes.tag": "Тег",
  "Memes.description": "Описание",
  "Memes.textUp": "Контекст текста сверху",
  "Memes.textDown": "Контекст текста снизу",
  "Memes.fileId": "Квадратная картинка",
  "Memes.aspects": "Аспекты",
  "Memes.templateId": "ID шаблона",
  "Memes.exampleImageUrl": "Пример изображения",

  Tags: "Теги",
  "Tags.Add": "Добавить тег",
  "Tags.Form": "Редактировать данные",
  "Tags.Info": "Информация",
  "Tags.name": "Название",

  SocialActionBlock: "Блок социального взаимодействия",
  "SocialActionBlock.imageTag": "Тег для картинки",

  "SocialActionBlock.imageCategoryId": "Категория для картинки в сообщении",
  "SocialActionBlock.notificationImageCategoryId": "Категория для картинки в уведомлении",
  "SocialActionBlock.imageTextUp": "Надпись на картинке сверху",
  "SocialActionBlock.imageTextDown": "Надпись на картинке снизу",
  "SocialActionBlock.notificationTextUp": "Надпись на картинке в уведомлении сверху",
  "SocialActionBlock.notificationTextDown": "Надпись на картинке в уведомлении снизу",
  "SocialActionBlock.notificationMessageText": "Текст сообщения в уведомлении",
  "SocialActionBlock.notificationMessageButton": "Надпись на кнопке в уведомлении",
  "SocialActionBlock.actionButtonText": "Кнопка действия",
  "SocialActionBlock.confirmTitleText": "Заголовок `Подтвердить`",
  "SocialActionBlock.confirmMainText": "Текст `Подтвердить`",
  "SocialActionBlock.confirmButtonText": "Кнопка `Подтвердить`",
  "SocialActionBlock.actionSuccessTitle": "Заголовок при успехе",
  "SocialActionBlock.actionSuccessText": "Текст при успехе",
  "SocialActionBlock.actionSuccessButton": "Текст на кнопке при успехе",
  "SocialActionBlock.actionFailureTitle": "Заголовок при ошибке",
  "SocialActionBlock.actionFailureText": "Текст при ошибке",
  "SocialActionBlock.actionFailureButton": "Текст на кнопке при ошибке",
  "SocialActionBlock.messageMainText": "Текст сообщения в телеграме",
  "SocialActionBlock.messageButtonText": "Текст на кнопке в телеграме",

  //
  Www: "Cайт",
  "Www.Settings": "Настройки",
  "Www.Settings.astroWeekCost": "Стоимость покупки прогноза на неделю",
  "Www.Settings.phone": "Номер телефона",
  "Www.Settings.email": "Email",
  "Www.Settings.address": "Адрес",
  "Www.Settings.intro_video": "Вступительное видео (код для вставки)",
  "Www.Settings.whatsapp": "Ссылка в WhatsApp",
  "Www.Settings.instagram": "Ссылка на инстаграм",
  "Www.Settings.facebook": "Ссылка на Фейсбук",
  "Www.Settings.vk": "Ссылка на Вконтакте",
  "Www.Settings.telegram": "Ссылка на Telegram",
  "Www.Settings.telegramAppName": "Название приложение в Telegram",
  "Www.Settings.shareButtonText": "Надпись на кнопке `Поделиться` на сайте",
  "Www.Settings.shareScreenButton": "Надпись на кнопке в сообщении `Поделиться`",
  "Www.Settings.shareScreenTitle": "Пояснительный текст в сообщении `Поделиться`",
  "Www.Settings.settingsIntroTitle": "Вступительный заголовок настроек",
  "Www.Settings.settingsIntroText": "Вступительный текст настроек",
  "Www.Settings.premiumButtonText": "Надпись на кнопке для покупки `Премиума`",
  "Www.Settings.premiumLockButtonText": "Надпись на кнопке блокировки `Премиума`",
  "Www.Settings.premiumSuccessButton": "Надпись на кнопке при успешной покупке `Премиума`",
  "Www.Settings.premiumSuccessText": "Текст при успешной покупке `Премиума`",
  "Www.Settings.premiumSuccessTitle": "Заголовок при успешной покупке `Премиума`",

  "Www.Settings.notificationTime": "Время отправки уведомлений (в формате `H:mm`)",
  "Www.Settings.notificationCategoryId": "Категория мемов для ежедневных уведомлений",
  "Www.Settings.notificationTextUp": "Верхний текст на меме",
  "Www.Settings.notificationTextDown": "Нижний текст на меме",
  "Www.Settings.notificationButtonText": "Текст на кнопке в сообщении",
  "Www.Settings.notificationMessageText": "Текст  в сообщении",

  "Www.Settings.allowMerchShop": "Активировать раздел `Мерч`",
  "Www.Settings.shopScreenTitle": "Заголовок раздела `Магазин`",
  "Www.Settings.shopScreenText": "Текст раздела `Магазин`",
  "Www.Settings.shopMerchTitle": "Заголовок раздела `Мерч`",
  "Www.Settings.shopMerchText": "Текст раздела `Мерч`",
  "Www.Settings.shopMerchButton": "Кнопка раздела `Мерч`",
  "Www.Settings.shopMerchUrl": "Ссылка на магазин мерча",
  "Www.Settings.logoImageId": "Логотип",
  "Www.Settings.premiumImageId": "Картинка для описания премиума",
  "Www.Settings.merchImageId": "Картинка для мерча",
  "Www.Settings.giftScreenButton": "Надпись на кнопке `Подарки`",
  "Www.Settings.giftStoryTitle": "Заголовок для модалки `История подарков`",
  "Www.Settings.giftScreenTitle": "Заголовок для модалки `Подарки`",
  "Www.Settings.giftScreenText": "Текст для модалки `Подарки`",
  "Www.Settings.telegramAppUrl": "Ссылка на приложение в Telegram",
  "Www.Settings.telegramChannelUrl": "Ссылка на канал в Telegram",
  "Www.Settings.telegramCommunityUrl": "Ссылка на сообщество в Telegram",
  "Www.Settings.description": "Описание сервиса",
  "Www.Settings.introVideo": "Вступительное видео",
  "Www.Settings.allowSelectCountry": "Выбор страны",
  "Www.Settings.versionNumber": "Версия",
  "Www.Settings.startBotMessage": "Приветственое сообщение в боте",
  "Www.Settings.supportBotMessage": "Сообщение на запрос поддержки в боте",
  "Www.Settings.freeFriends": "Количество бесплатных друзей",
  "Www.Settings.defaultImageId": "Картинка по умолчанию",
  "Www.Settings.helpImageId": "Картинка при регистрации",
  "Www.Settings.helpText": "Текст помощи пользователю",
  "Www.Settings.stopText": "Текст ошибки регистрации астрогочи",
  "Www.Settings.noFriendsText": "Текст если нет друзей",
  "Www.Settings.noFriendsImageId": "Картинка если нет друзей",
  "Www.Settings.shareScreenText": "Текст на экране `поделиться`",
  "Www.Settings.softToys": "Описание мягких игрушек",
  "Www.Settings.digitalToys": "Описание цифровых игрушек",
  "Www.Settings.giftAction": "Тексты для Подарков",
  "Www.Settings.inviteAction": "Тексты для Инвайтов",

  "Www.Landing": "Лендинг",
  "Www.Landing.intro": "Титульный блок",
  "Www.Landing.info": "Информация/виджет",
  "Www.Landing.howto": "Как это работает",
  "Www.Landing.score": "Оценка",
  "Www.Landing.block1": "Блок 1",
  "Www.Landing.block2": "Блок 2",
  "Www.Landing.block3": "Блок 3",
  "Www.Landing.mission": "Миссия",
  "Www.Landing.technologies": "Технологии",
  "Www.Landing.technologies2": "Технологии 2",
  "Www.Landing.technologies3": "Технологии 3",
  "Www.Landing.shop": "Магазин",

  "Www.Landing.title": "Название",
  "Www.Landing.content": "Содержание",
  "Www.Landing.image": "Изображение",

  "Www.Meta": "Мета-данные",
  "Www.Meta.index": "Главная страница",
  "Www.Meta.account": "Аккаунт",
  "Www.Meta.shared": "Поделиться картинкой",

  "Www.Meta.title": "Заголовок",
  "Www.Meta.description": "Описание",
  "Www.Meta.keywords": "Ключевые слова",

  "Www.Faq": "FAQ",
  "Www.Faq.Add": "Добавить вопрос",
  "Www.Faq.Form": "Редактировать вопрос",
  "Www.Faq.title": "Название",
  "Www.Faq.ident": "ident",
  "Www.Faq.type": "Тип",
  "Www.Faq.question": "Вопрос",
  "Www.Faq.answer": "Ответ",

  "Www.Contents": "Контент",
  "Www.Contents.Add": "Добавить новый",
  "Www.Contents.Form": "Редактировать контент",
  "Www.Contents.title": "Заголовок",
  "Www.Contents.data": "Содержимое",
  "Www.Contents.image": "Изображение",
  "Www.Contents.url": "Ссылка",

  "Www.Feedbacks": "Обратная связь",
  "Www.Feedbacks.Add": "Добавить запись",
  "Www.Feedbacks.Form": "Редактировать запись",
  "Www.Feedbacks.realDate": "Реальная дата отзыва",
  "Www.Feedbacks.indexPage": "Показывать на главной",
  "Www.Feedbacks.localesName": "Автор",
  "Www.Feedbacks.localesText": "Отзыв",
  "Www.Feedbacks.images": "Изображения",
  "Www.Feedbacks.value": "Оценка",

  meta: "Мета аттрибуты",
  "meta.title": "Заголовок",
  "meta.description": "Описание",
  "meta.keywords": "Ключевые слова",
  "meta.image": "Изображение",
  error: "Ошибка",
  "error.auth-error": "Ошибка авторизации",
  confirmAction: "Подтвердите действие",
  date: "Дата",
  dateTime: "Дата/время",
  ok: "Подтвердить",
  save: "Cохранить",
  saving: "Данные сохраняются",
  saved: "Данные сохранены",
  add: "Добавить",
  edit: "Редактировать",
  delete: "Удалить",
  remove: "Удалить",
  cancel: "Отмена",
  total: "Всего",
  logout: "Выйти",
  "not-found": "не найдено",
  loading: "Идет загрузка",
  noData: "Нет данных",
  "tables.title": "Название",
  // "tables.enabled": "Заблокировано",
  "tables.blocked_filter": "Заблокированные",
  "tables.enabled": "Активность",
  "tables.enabled.yes": "Активен",
  "tables.enabled.no": "Неактивен",
  "tables.status": "Статус",
  "tables.created": "Дата",
  "tables.updated": "Обновлено",
  dateFormat: "DD.MM.YYYY",
  timeFormat: "HH:mm:ss",
  dateTimeFormat: "DD.MM.YYYY HH:mm",
  "pager.limit": "Кол-во на странице",
  free: "Бесплатно",
  url: "Ссылка",
  //
  symbol: "Символ",
  name: "Название",
  localesName: "Локализация названия",
  localesText: "Локализация текстов",
  value: "Значение",
  oldValue: "Старое значение",
  type: "Тип",
  description: "Описание",

  // типовые значения
  yes: "Да",
  no: "Нет",
  close: "Закрыть",
  select_value: "Выберите значение",
  urlCopied: "Ссылка скопирована",

  blocked_filter: "Показать/скрыть заблокированные",

  Special: "Служебные",
  "Special.DemoCustomers": "Демо-клиенты",
  "Special.Translates": "Переводы",
  "Special.Translates.Add": "Новая задача",
  "Special.Translates.referenceName": "Модель данных",
  "Special.Translates.sourceLocale": "Исходная локализация",
  "Special.Translates.targetLocale": "Целевая локализация",
  "Special.Translates.skipTranslated": "Пропускать уже переведенные",
  "Special.Translates.status": "Статус",
  "Special.Translates.symbolsCount": "Кол-во символов",
  "Special.Translates.detectedId": "Всего записей",
  "Special.Translates.completedId": "Завершено",
  "Special.Translates.skippedId": "Пропущено",
  "Special.Translates.errorId": "Ошибки",

  "Special.JobsGenerates": "Задачи генерации мемов",
  "Special.JobsGenerates.Add": "Новая задача",
  "Special.JobsGenerates.name": "Название",
  "Special.JobsGenerates.completion": "Генератор",
  "Special.JobsGenerates.memes": "Мемы",
  "Special.JobsGenerates.categories": "Категории",
  "Special.JobsGenerates.status": "Статус",
  "Special.JobsGenerates.progress": "Прогресс, %",
  "Special.JobsGenerates.aspectsBreak": "Каждый аспект по отдельности",
  "Special.JobsGenerates.run": "Запустить выполнение",

  "Special.MemeGenerates": "Генерация мемов",
  "Special.MemeGenerates.Add": "Новый шаблон",
  "Special.MemeGenerates.name": "Название",
  "Special.MemeGenerates.content": "Тип контента",
  "Special.MemeGenerates.gptModel": "Модель ChatGPT",
  "Special.MemeGenerates.dataGroup": "Группировка данных",
  "Special.MemeGenerates.instructions": "Инструкции",
  "Special.MemeGenerates.runMemes": "Создать",
  "Special.MemeGenerates.saveMemes": "Сохранить",

  Auth: "Авторизация",
  "Auth.login": "Логин",
  "Auth.password": "Пароль",
  "Auth.doLogin": "Вход",
  "Auth.forgotPassword": "Забыли пароль?",
  "Auth.enterRestoreData": "Введите номер телефона, на который зарегистрирована учетная запись",
  "Auth.confirmCode": "Введите проверочный код",
  "Auth.doRequestCode": "Запросить проверочный код",
  "Auth.doConfirmCode": "Подтвердить код",
  "Auth.wrong-login": "Неверный логин",
  "Auth.wrong-code": "Неверный код",
  "Auth.wrong-user": "Недоступный пользователь",
  "Auth.wrong-password": "Неверный пароль",
  "Auth.wrong-data": "Неверные данные",
  "Auth.login-required": "Требуется ввести логин",
  "Auth.password-required": "Требуется ввести пароль",
  "Auth.back": "На страницу авторизации",
  "Auth.in-progress": "Авторизация в системе",

  "sidebar.demo": "Демо",
  "sidebar.demopage": "Демо страница",
  "sidebar.dashboard": "Сводка",
  "sidebar.pages": "Страницы",
  "sidebar.authentication": "Authentication",
  "sidebar.login": "Login",
  "sidebar.register": "Register",
  "sidebar.forgotpassword": "Forgot Password",
  "sidebar.userpages": "User Pages",
  "sidebar.lockscreen": "Lock Screen",
  "sidebar.timeline": "Timeline",
  "sidebar.userprofile": "User Profile",
  "sidebar.error": "Error",
  "sidebar.400": "400",
  "sidebar.forms": "Forms",
  "sidebar.regularforms": "Regular Forms",
  "sidebar.extendedforms": "Extended Forms",
  "sidebar.validationforms": "Validation Forms",
  "sidebar.wizard": "Wizard",
  "sidebar.chat": "Chat",
  "sidebar.select": "Select",
  "sidebar.selectcontrols": "Select Controls",
  "sidebar.googlemaps": "Google Maps",
  "sidebar.fullscreenmap": "Full Screen Map",
  "sidebar.vectormap": "Vector Map",
  "sidebar.tables": "Tables",
  "sidebar.regulartabels": "Regular Tables",
  "sidebar.extendedtables": "Extended Tables",
  "sidebar.reacttables": "React Table",
  "sidebar.components": "Components",
  "sidebar.buttons": "Buttons",
  "sidebar.custombuttons": "Custom Buttons",
  "sidebar.gridsystem": "Grid System",
  "sidebar.panels": "Panels",
  "sidebar.sweetalert": "Sweet Alert",
  "sidebar.notifications": "Notifications",
  "sidebar.icons": "Icons",
  "sidebar.typography": "Typography",
  "sidebar.widgets": "Widgets",
  "sidebar.charts": "Charts",
  "sidebar.calender": "Calender",
  "sidebar.gauges": "Gauges",
  "sidebar.datepicker": "DatePicker",
  "sidebar.reactstrapcomponents": "React Strap",
  "sidebar.alerts": "Alerts",
  "sidebar.badges": "Badges",
  "sidebar.breadcrumbs": "Breadcrumbs",
  "sidebar.buttondropdown": "Button Dropdown",
  "sidebar.buttongroup": "Button Group",
  "sidebar.card": "Card",
  "sidebar.carousel": "Carousel",
  "sidebar.collapse": "Collapse",
  "sidebar.dropdowns": "Dropdowns",
  "sidebar.fade": "Fade",
  "sidebar.form": "Form",
  "sidebar.maps": "Maps",
  "sidebar.tracking-vehicle": "Tracking Vehicle",
  "sidebar.inputgroup": "Input Group",
  "sidebar.jumbotron": "Jumbotron",
  "sidebar.layout": "Layout",
  "sidebar.listgroup": "ListGroup",
  "sidebar.media": "Media",
  "sidebar.modals": "Modals",
  "sidebar.navbar": "Navbar",
  "sidebar.nav": "Nav",
  "sidebar.pagination": "Pagination",
  "sidebar.popovers": "Popovers",
  "sidebar.progress": "Progress",
  "sidebar.tabs": "Tabs",
  "sidebar.tooltips": "Tooltips",
  "sidebar.app": "Приложение",
  "sidebar.userinterface": "User Interface",
  "sidebar.style&userinterface": "Style & User Interface",
  "sidebar.uicomponents": "Ui Components",
  "sidebar.others": "Others",
  "sidebar.menuexamples": "Examples",
  "sidebar.500": "500",
  "sidebar.single": "Single",
  "sidebar.onelevel": "One Level",
  "sidebar.secondlevel": "Second Level",
  "sidebar.example": "Example",
  "sidebar.example1": "Example 1",
  "sidebar.example2": "Example 2",
  "sidebar.colors": "Colors",
  "sidebar.pricing": "Pricing",
  "sidebar.style1": "Style 1",
  "sidebar.style2": "Style 2",
  "sidebar.contact": "Contact",
  "sidebar.themes": "Themes",
  "sidebar.basicelement1": "Basic Element 1",
  "sidebar.basicelement2": "Basic Element 2",
  "sidebar.advanceelement": "Advance Element",
  "sidebar.pricingpages": "Pricing Pages",
  "sidebar.errorpages": "Error Pages",
  "sidebar.scrumboard": "Scrumboard",
  "sidebar.editors": "Editors",
  "sidebar.roeeditor": "Roe Editor",
  "sidebar.roemarkupeditor": "Roe Markup Editor",
  "sidebar.wysiwygeditor": "Wysiwyg Editor",
  "sidebar.quill": "Quill",
  "sidebar.quilleditor": "Quill Editor",
  "sidebar.foreditor": "For Editor",
  "sidebar.socialwidgets": "Social Widgets",
  "sidebar.chartwidgets": "Chart Widgets",
  "sidebar.blogwidgets": "Blog Widgets",
  "sidebar.statisticswidgets": "Statistics Widgets",
  "sidebar.filemanager": "File Manager",
  "sidebar.todos": "Todos",
  "themeChanger.settings": "Settings",
  "themeChanger.sidebar": "Sidebar Varients",
  "themeChanger.footer": "Footer",
  "themeChanger.background": "Background",
  "themeChanger.topbar": "Topbar",
  "themeSetting.layouts": "Layouts",
  "themeSetting.vertical": "Vertical",
  "themeSetting.horizontal": "Horizontal",
  "themeSetting.themebuilder": "Theme Builder",
  "themeSetting.alignments": "Alignments",
  "themeSetting.topbar": "Topbar",
  "themeSetting.footer": "Footer",
  "themeSetting.display": "Display",
  "themeSetting.below": "Below",
  "themeSetting.above": "Above",
  "themeSetting.show": "Show",
  "themeSetting.hide": "Hide",
  "themeSetting.on": "On",
  "themeSetting.off": "Off",
  "themeSetting.tranperentmod": "Transperent Mode",
  "themeSetting.sidebartransparent": "Sidebar Transparent",
  "themeSetting.activepanel": "Sidebar Variant",
  "themeSetting.style1": "style1",
  "themeSetting.style2": "style2",
  "themeSetting.style3": "style3",
  "themeSetting.style4": "style4",
  "themeSetting.colorSchema": "Sidebar Scheme",
  "themeSetting.sidebarmini": "Sidebar Mini",
  "themeSetting.gradients": "Gradients",
  "languageSwitcher.label": "Language",
  "header.editprofile": "Edit Profile",
  "header.settings": "Settings",
  "header.inbox": "Inbox",
  "header.signout": "Signout",
};
