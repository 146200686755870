/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Uploader, ImagePreview, PageTitle } from "components/standart";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { LocalesValues, GendersSelector, ZodiacSelector } from "components/standart";
import { Alert } from "reactstrap";
import { FloatAspectsValues, FloatTags } from "components/special/contents";

import adminAPI from "redux/api/admin";
import { SwitchValue } from "components/special/forms";

import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  const refreshImageHandler = () => {
    const confirm = window.confirm(
      `Сгенерировать пример картинки для шаблона ${content.templateId}?`
    );

    if (confirm) {
      const body = { templateId: content.templateId };
      props.makeImage(
        { params, body },
        {
          onRequest() {
            setImageLoading(true);
          },
          onFailure() {
            setImageLoading(false);
          },
          onSuccess(file) {
            setImageLoading(false);
            setContent({ ...content, exampleImageUrl: file.location });
          },
        }
      );
    }
  };

  const uploadLogoFilesHandler = ([file]) => {
    const fileId = file._id;
    setContent({ ...content, fileId });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.name" />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="name"
                        value={content.name || ""}
                        onChange={changeContent}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.tag" />
                    </label>
                    <div className="col-8">
                      <FloatTags
                        _id={content.tagId}
                        showTitle={true}
                        showCount={false}
                        multi={false}
                        successHandler={([tagId]) => {
                          setContent({ ...content, tagId });
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.description" />
                    </label>
                    <div className="col-8">
                      <textarea
                        rows={4}
                        name="description"
                        value={content.description || ""}
                        onChange={changeContent}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.textUp" />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="textUp"
                        value={content.textUp || ""}
                        onChange={changeContent}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.textDown" />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="textDown"
                        value={content.textDown || ""}
                        onChange={changeContent}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.aspects" />
                    </label>
                    <div className="col-8">
                      <FloatAspectsValues
                        _id={content.aspectValuesId}
                        showCount={true}
                        multi={true}
                        className="btn-sm"
                        showTitle={false}
                        successHandler={(aspectValuesId) => {
                          setContent({ ...content, aspectValuesId });
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.fileId" />

                      <Uploader
                        className="btn-success btn-sm pull-right"
                        successHandler={uploadLogoFilesHandler}
                        imagesOnly="true"
                        multiple={false}
                      />
                    </label>
                    <div className="col-8">
                      {content?.fileId && (
                        <ImagePreview
                          file={{ _id: content.fileId }}
                          deleteHandler={() => {
                            setContent({ ...content, fileId: null });
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="Memes.templateId" />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="templateId"
                        value={content.templateId || ""}
                        onChange={changeContent}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-4 col-form-label">
                      <IntlMessages id="Memes.exampleImageUrl" />
                      <div className=" pull-right">
                        <button className="btn btn-secondary btn-sm" onClick={refreshImageHandler}>
                          <i className={`fa fa-sync-alt ${imageLoading ? "fa-spin" : ""}`} />
                        </button>
                      </div>
                    </div>
                    <div className="col-8">
                      {content.exampleImageUrl ? (
                        <img src={content.exampleImageUrl} style={{ maxWidth: "100%" }} />
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <IntlMessages id="tables.enabled" />
                    </label>
                    <div className="col-8 my-auto">
                      <SwitchValue value={content.enabled} onChange={changeContent} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-4 col-auto">
                      <button
                        className="form-control c-primary"
                        type="button"
                        onClick={saveHandler}
                      >
                        <IntlMessages id="save" />
                      </button>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-4">
                  <GiphyPreview tag={content.tagName} />
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { contentForm, content } = props;
  return (
    <Link to={contentForm(content)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

const checkContent = api.post(apiUrls.memesCheck);
export const CheckTemplatesButton = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const checkMemesHandler = () => {
    const confirm = window.confirm("Запустить проверку шаблонов?");
    if (confirm) {
      dispatch(
        checkContent(
          {},
          {
            onRequest() {
              setLoading(true);
            },
            onSuccess() {
              setLoading(false);
            },
          }
        )
      );
    }
  };
  return (
    <button className="btn btn-secondary btn-sm" onClick={checkMemesHandler}>
      <i className={`fa fa-sync-alt ${loading ? "fa-spin" : ""}`} />
    </button>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.memesInfo),
  makeImage: api.post(apiUrls.memesImage),
})(ContentForm);
