/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FloatAspectsCategories, FloatMemes } from "components/special/contents";
import { FloatMemeGenerates } from "components/special/specials";
import { setOnChange } from "components/special";
import { SwitchValue } from "components/special/forms";

import IntlMessages from "util/intlMessages";

const JobData = (props) => {
  const { job, setJob } = props;

  const changeData = setOnChange(job, setJob);

  return (
    <>
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.JobsGenerates.name" />
        </label>
        <div className="col">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            name={"name"}
            value={job.name || ""}
            onChange={changeData}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.JobsGenerates.completion" />
        </label>
        <div className="col">
          <FloatMemeGenerates
            _id={job.completionId}
            className="btn-sm"
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([completionId]) => {
              setJob({ ...job, completionId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.JobsGenerates.memes" />
        </label>
        <div className="col">
          <FloatMemes
            _id={_.join(job.memesId, ",")}
            className="btn-sm"
            query={{ everything: false }}
            showTitle={false}
            showCount={true}
            multi={true}
            successHandler={(memesId) => {
              setJob({ ...job, memesId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Special.JobsGenerates.categories" />
        </label>
        <div className="col">
          <FloatAspectsCategories
            _id={_.join(job.categoriesId, ",")}
            className="btn-sm"
            showTitle={false}
            showCount={true}
            multi={true}
            successHandler={(categoriesId) => {
              setJob({ ...job, categoriesId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Special.JobsGenerates.aspectsBreak" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue value={job.aspectsBreak} name={"aspectsBreak"} onChange={changeData} />
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(JobData);
