import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import adminAPI from "redux/api/admin";
import { apiUrls, jobsForm } from "./init";
import JobData from "./Data";

const { api } = adminAPI.actions;

const AddTranslationJob = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [job, setJob] = useState({});
  const [disabled, setDisabled] = useState({});

  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(jobsForm(body)));
    };
    props.addJob({ body: job }, { onSuccess });
  };

  useEffect(() => {
    setJob({});
  }, [openModal]);

  useEffect(() => {
    setDisabled(!(job.name && _.size(job.memesId) && _.size(job.categoriesId) && job.completionId));
  }, [job]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Special.JobsGenerates.Add" />
          </ModalHeader>
          <ModalBody>
            <JobData {...{ job, setJob }} />

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={disabled}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addJob: api.post(apiUrls.jobs),
})(AddTranslationJob);
